@import "src/app/global/variables.scss";
.SelectContainer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0;
    color: $black-blue;
    font-size: 20px;
    font-weight: 400;
    cursor: pointer;
    padding: 0px 0px;
    background-color: white;
    border-radius: 5px;
}

.selectMenu {
    position: absolute;
    display: none;
    flex-direction: column;
    align-items: center;
    padding: 24px 0px;
    top: 100%;
    background-color: white;
    color: #7c7c7c;
    font-size: 14px;
    font-weight: 500;
    border-radius: 5px;
    border: 1px solid #cecece;
    box-shadow: 0px 219px 61px 0px rgba(0, 0, 0, 0),
        0px 140px 56px 0px rgba(0, 0, 0, 0.01),
        0px 79px 47px 0px rgba(0, 0, 0, 0.02),
        0px 35px 35px 0px rgba(0, 0, 0, 0.03),
        0px 9px 19px 0px rgba(0, 0, 0, 0.04);
    z-index: 999;
}

.selectMenu.expanded {
    display: flex;
}

.selectOption {
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% - 48px);

    cursor: pointer;
    transition-duration: 300ms;
    padding: 6px 24px;
}

.selectOption.selected {
    color: $secondary-color;
    background-color: #eef9ff;
}

.selectOption.multi {
    justify-content: space-between;
    margin-left: 24px;
    margin-right: 24px;

    &:hover {
        background-color: white;
        color: inherit;
    }
}

.selectOption:hover {
    background-color: $secondary-color;
    color: white;
}

.Icon {
    path {
        stroke: gray;
    }
    transition-duration: 300ms;
    transform: rotateZ(0);
}

.Icon.expanded {
    transform: rotateZ(180deg);
}
