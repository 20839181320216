@import "src/app/global/variables.scss";
.title {
    text-transform: uppercase;
    font-weight: 500;
    
}

.textContainer {
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 32px;
    min-width: 530px;
    max-width: 530px;

    h2 {
        text-transform: capitalize;
        color: $black-blue;
    }

    p {
        color: $gray-color;
    }

    @media screen and (max-width: 767px) {
        padding-left: 0px;
        min-width: 300px;
        max-width: 300px;
    }

    @media screen and (max-width: 1024px) and (min-width: 768px) {
        max-width: 324px;
        min-width: 0px;
        margin-top: 66px;
        h2 {
            font-size: 32px;
        }

        p {
            font-size: 14px;
        }
    }

    @media screen and (min-width: 1024px) and (max-width: 1280px) {
        left: 64px;
    }
}

.aboutImage {
    @media screen and (max-width: 767px) {
        width: 320px;
        height: 320px;
        position: relative;
    }

    @media screen and (max-width: 1024px) and (min-width: 768px) {
        width: 320px;
        height: 320px;
        min-width: 320px;
        min-height: 320px;
    }

    @media screen and (min-width: 1800px) {
        width: 50%;
        height: 50%;
    }
}

.miniImage {
    position: absolute;
    top: 628px;
    left: 431px;

    @media screen and (max-width: 1024px) {
        display: none;
    }
}

.readMoreButton {
    max-width: 100%;

    &:hover {
        .ArrowRight {
            transform: translateX(8px);
        }
    }

    @media screen and (max-width: 767px) {
        width: 100%;
    }
}
