@import "src/app/global/variables.scss";
.OurTeam {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 140px;

    h2 {
        color: $black-blue;
    }
    h4 {
        color: $black-blue;
        font-size: 24px;
        font-weight: 700;
    }

    @media screen and (max-width: 767px) {
        margin-top: 64px;
        h2 {
            font-size: 32px !important;
            margin-left: 24px;
            place-self: start;
        }
    }
}
.teammateContainer {
    margin-top: 27px;
    display: grid;
    gap: 118px;
    row-gap: 100px;
    grid-template-columns: 2fr 2fr 2fr;
    margin-left: 222px;
    margin-right: 222px;

    @media screen and (max-width: 1024px) {
        display: flex;
        gap: 56px;
        margin-left: 0px;
        margin-right: 56px;
        margin-bottom: 56px;
        margin-top: 56px;
    }
}
.teamMarquee {
    width: 100vw;
    max-width: 100vw;

    @media screen and (max-width: 767px) {
        width: 100vw;
    }
}
.teammate {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
        border-radius: 9999px;
        width: 240px;
        height: 240px;
        object-fit: cover;
        margin-bottom: 42px;
    }
}
