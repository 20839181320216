@import "src/app/global/variables.scss";
.HomeBlock {
    color: $text-color;
    height: 900px;
    padding-top: 100px;
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 1024px) {
        height: 500px;
    }

    @media screen and (max-width: 767px) {
        align-items: center;
        height: auto;
        padding-top: 0;
        flex-direction: column-reverse;
        gap: 24px;
        color: $black-blue;
        h1, h2 {
            font-size: 36px !important;
        }
    }
    h1, h2 {
        font-size: 48px;
    }
}

.Background {
    position: absolute;
    width: 100vw;

    max-height: 1200px;
    left: 0;
    top: 0px;
    z-index: 2;
    pointer-events: none;
    @media screen and (max-width: 767px) {
        padding-top: 200px;
    }
}
.bgImage {
    position: absolute;
    top: 0;
    z-index: 2;
    height: 650px;
    width: 100%;

    @media screen and (min-width: 767px) {
        display: none;
    }
}

.description {
    @media screen and (max-width: 767px) {
        max-width: 300px;
    }
}

.frameContainer {
    max-width: 800px;
    overflow: hidden;
    display: flex;
    flex-wrap: nowrap;
    margin-top: 80px;
    position: relative;
    z-index: 3;

    @media screen and (max-width: 1024px) {
        height: 500px;
    }

    @media screen and (max-width: 767px) {
        padding: 110px 24px 100px 24px;
        height: 250px;
        align-items: center;
    }

    @media screen and (min-width: 1920px) {
        max-width: none;
        position: absolute;
        width: 1300px;
        left: 50vw;
    }
}

.Frame {
    position: relative;
    z-index: 4;
    left: 100px;

    img {
        object-fit: cover;
        width: 720px;
    }

    @media screen and (max-width: 767px) {
        left: 40px;
        img {
            width: 400px;
        }
    }


    @media screen and (max-width: 1024px) and (min-width: 768px) {
        left: 0px;
        img {
            width: 425px;
        }
    }

    @media screen and (max-width: 1440px) and (min-width: 1025px) {
        img {
            width: 500px;
        }
    }
}

.Dots {
    position: absolute;
    top: 400px;
    left: 600px;
    z-index: 1;
}

.BackGraph {
    position: absolute;
    top: -120px;
    left: 120px;
    width: 100%;
    height: 100%;
    z-index: 1;
    pointer-events: none;

    @media screen and (max-width: 767px) {
        display: none;
    }

    @media screen and (max-width: 1440px) {
        top: -100px;
    }
    @media screen and (max-width: 1024px) and (min-width: 768px) {
        top: 40px;
    }
}

.titleContainer {
    position: relative;

    width: 600px;
    min-width: 600px;
    margin-top: 150px;
    padding-left: 120px;

    z-index: 3;

    h1, h2 {
        text-transform: capitalize;
        margin: 0;
    }

    h4 {
        margin-bottom: 56px;
    }
    @media screen and (max-width: 1024px) {
        margin-top: 16px;
        min-width: auto;
        padding-left: 64px;
    }

    @media screen and (max-width: 1024px) and (min-width: 768px) {
        h1, h2 {
            font-size: 36px;
        }
    }

    @media screen and (max-width: 1440px) {
        margin-top: 100px;
    }

    @media screen and (max-width: 767px) {
        padding-left: 24px;
        margin-top: 40px;
        max-width: fit-content;
    }
}

.getButton {
    position: relative;
    z-index: 2;

    @media screen and (max-width: 767px) {
        width: calc(100% - 24px);
    }
}
