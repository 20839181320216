@import "src/app/global/variables.scss";
.Post {
    border-radius: 20px;
    border: 1px solid #fff;
    background: #fff;
    border-radius: 20px;
    border: 1px solid #fff;
    background: #fff;
    box-shadow: 0px 5px 15px 1px
        rgba($color: #000000, $alpha: 0.05);
    backdrop-filter: blur(137px);
    backdrop-filter: blur(137px);

    padding: 40px 44px;
    width: 387px;

    img {
        width: 46px;
        height: 46px;
        border-radius: 5px;
    }

    @media screen and (max-width: 767px) {
        width: 280px;
    }
}

.postText {
    color: $gray-color;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;

    height: 150px;
    overflow: hidden;
}

.profile {
    display: flex;
    align-items: center;
    margin-top: 24px;
    gap: 16px;
}

.nameContainer {
    display: flex;
    flex-direction: column;
}

.role {
    color: #9e9e9e;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
}

.name {
    color: $secondary-color;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%; /* 32px */
}

.avatar {
    width: 46px;
    height: 46px;
    flex-shrink: 0;
}
