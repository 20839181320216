@import "src/app/global/variables.scss";
.content {
    // background-color: red;
    margin-top: 256px;
}

.bgWave {
    position: absolute;
    width: 100%;
    height: 400px;
    z-index: -1;
    top: 0;
    left: 0;
}

.title {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 127px;
    color: white;
}

.footer {
    margin-top: 156px;
    position: relative;
}
