@import "src/app/global/variables.scss";
.CookieNotice {
    position: fixed;
    bottom: 0;
    left: 0;

    background-color: $secondary-color;
    width: calc(100%);
    padding: 26px 0px;

    display: flex;
    justify-content: space-between;

    z-index: 9999;

    transition-duration: 500ms;

    &.visited {
        display: none;
        transition-duration: 0;
    }

    &.hide {
        transform: translateY(200px);
        opacity: 0;
        pointer-events: none;
    }

    @media screen and (max-width: 767px) {
        flex-direction: column;
        width: calc(100% - 48px);
        gap: 12px;
        padding: 24px 24px;
    }

    @media screen and (max-width: 1200px) {
        flex-direction: column;
    }
}

.text {
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
    max-width: 600px;
    margin-left: 106px;

    @media screen and (max-width: 767px) {
        margin-left: 0;
    }

    @media screen and (max-width: 1200px) {
        max-width: max-content;
        margin-right: 106px;
    }
}

.buttonContainer {
    display: flex;
    gap: 24px;
    margin-right: 106px;

    @media screen and (max-width: 1200px) {
        margin-left: 106px;
        margin-top: 32px;
    }

    @media screen and (max-width: 767px) {
        flex-direction: column;
        gap: 16px;
        width: 100%;
        margin-left: 0px;
        margin-right: 0px;
    }
}

.decline {
    background-color: $secondary-color;
    border: 1px solid white;
    padding: 16px 42px;
    width: max-content;
    @media screen and (max-width: 1200px) {
        width: max-content;
    }
    @media screen and (max-width: 767px) {
        width: 100%;
    }
}

.accept {
    width: max-content;
    @media screen and (max-width: 1200px) {
        width: max-content;
    }

    @media screen and (max-width: 767px) {
        width: 100%;
    }
}
