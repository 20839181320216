@import "src/app/global/variables.scss";
.PricingCard {
    position: relative;
    z-index: 10;
    border-radius: 8.276px;
    border: 1.294px solid #cecece;
    background: #fff;
    box-shadow: 0px 10.348px 41.393px 0px
        rgba(59, 59, 59, 0.04);
    padding: 22px 40px;
    max-width: 370px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 32px;

    h3 {
        color: $black-blue;
    }

    transition-duration: 500ms;

    &:hover {
        transform: scale(1.1);
    }

    &.mobile {
        &:hover {
            transform: scale(1);
        }
    }

    &.recommended {
        .prefers {
            margin-top: -16px;
        }

        .orderButton {
            width: 100%;
            max-width: 243px;
            font-size: 18px;
        }
    }
    h4 {
        color: $primary-color;
        text-align: center;
        font-variant-numeric: lining-nums proportional-nums;
        font-family: "Raleway";
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
    @media screen and (min-width: 1800px) {
        padding: 22px 0px;
    }
}

.titleContainer {
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
        font-size: 12px;
    }
}

.price {
    color: $black-blue;

    font-family: "Raleway";
    font-variant-numeric: lining-nums proportional-nums;

    font-size: 59.399px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.dollar {
    color: $black-blue;
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 29.699px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.period {
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 29.699px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.prefers {
    list-style: none;

    display: flex;
    flex-direction: column;
    gap: 16px;

    padding: 0;
}

.prefersItem {
    display: flex;
    align-items: center;
    justify-content: space-between;

    gap: 69px;

    p {
        color: #666;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        align-items: center;
    }
}

.orderButton {
    white-space: nowrap;
    &:hover {
        background-color: $secondary-color;
        border-color: $secondary-color;
        color: white;
    }
}

.vMark {
    max-width: 24px;
    max-height: 24px;
    min-width: 24px;
    min-height: 24px;
}

.xMark {
    max-width: 24px;
    max-height: 24px;
    min-width: 24px;
    min-height: 24px;
}
