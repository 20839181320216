@import "src/app/global/variables.scss";
.AbooutBlock {
    position: relative;
    // top: -417px;

    display: flex;
    flex-direction: column;
}

.AboutProjects {
    display: flex;

    padding-left: 120px;
    padding-right: 120px;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 220px;

    position: relative;
    margin-top: 100px;

    @media screen and (max-width: 1280px) {
        margin-top: -100px;
    }

    @media screen and (max-width: 1024px) {
        padding-left: 42px;
        padding-right: 42px;
        justify-content: center;
        margin-bottom: 64px;
        margin-top: 64px;
        gap: 56px;
    }

    @media screen and (max-width: 767px) {
        padding-left: 24px;
        padding-right: 24px;
        flex-direction: column;
        // gap: 56px;
    }

    @media screen and (min-width: 1600px) {
        margin-top: 300px;
    }

    @media screen and (min-width: 1800px) {
        margin-top: 400px;
    }
}

.features {
    position: relative;

    padding-left: 120px;
    display: flex;

    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;

    position: relative;

    @media screen and (max-width: 1024px) {
        padding-left: 42px;
    }

    @media screen and (max-width: 767px) {
        padding-left: 0px;
        flex-direction: column;

        width: calc(100% - 48x);
    }
}
