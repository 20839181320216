@import "src/app/global/variables.scss";
.container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.NotFoundPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    margin-top: 100px;

    @media screen and (max-width: 1024px) {
        margin-top: 50px;
        text-align: center;
        margin-left: 16px;
        margin-right: 16px;
        p {
            font-size: 14px;
        }
    }

    h1 {
        color: $black-blue;
    }
}

.logo {
    @media screen and (max-width: 767px) {
        position: relative;
        left: calc(50% - 20px);
    }
}

.image {
    position: absolute;
    z-index: -1;
    top: 250px;
    left: calc(50% - 325px);
    width: 650px;
}

.homeButton {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 400px;

    @media screen and (max-width: 1024px) {
        margin-top: 350px;
    }
}
