@import "src/app/global/variables.scss";
.BlogCard {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;

    max-width: 488px;
    border-radius: 12px;
    padding: 24px;
    background-color: white;
    box-shadow: 0px 10px 20px 5px
        rgba($color: black, $alpha: 0.05);
    transition-duration: 500ms;
    &:hover {
        transform: scale(1.05);
        cursor: pointer;
    }
    @media screen and (min-width: 768px) and (max-width: 1024px) {
        max-width: 400px;
    }
}

.BlogCard.withImage {
    flex-direction: row;
    gap: 32px;
    .image {
        display: block;
    }
    .dateContainer {
        margin-top: 16px;
    }

    .rightSide {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    @media screen and (max-width: 767px) {
        flex-direction: column;
    }
}

.title {
    color: $black-blue;
    font-size: 24px;
    font-weight: 700;
}

.description {
    color: #666;
    font-size: 18px;
    font-weight: 400;
}

.image {
    display: none;
    border-radius: 12px;
    width: 184px;
    height: 205px;
    object-fit: cover;

}
.dateContainer {
    margin-top: 34px;

    p {
        color: #666;
        font-size: 14px;
        font-weight: 400;

        &.date {
            color: $secondary-color;
        }
    }
}
