@import "src/app/global/variables.scss";
.content {
    margin-left: 216px;
    margin-right: 216px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    
    .body{
        display: flex;
        flex-direction: column;
        gap: 32px;
    }

    @media screen and (max-width: 1024px) {
        margin-left: 24px;
        margin-right: 24px;
    }
}

.title {
    display: flex;
    align-items: end;
    color: $black-blue;

    h1{
        @media screen and (min-width: 1024px) {
            font-size: 48px;
        }
    }
}

.withImage {
    display: flex;
    gap: 32px;

    @media screen and (max-width: 767px) {
        flex-direction: column;
    }
}

.text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.image {
    border-radius: 12px;
    object-fit: cover;

    @media screen and (max-width: 767px) {
        max-width: 100%;
        height: auto;
    }
}

.date {
    display: flex;
    flex-direction: column;
    align-items: end;
    white-space: nowrap;

    font-size: 14px;
    color: $gray-color;
    .num {
        color: $secondary-color;
    }
}

.pagination {
    display: flex;
    justify-content: space-between;
}
.prevPost,
.nextPost {
    display: flex;
    gap: 12px;
    color: $gray-color;
    align-items: center;
    cursor: pointer;
    svg path {
        stroke: $gray-color;
    }
}
.prevArrow {
    transform: rotate(180deg);
}

.posts {
    display: grid;
    grid-template-columns: 1fr 1fr;

    @media screen and (max-width: 767px) {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }
}
