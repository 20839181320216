@import "src/app/global/variables.scss";
.Frame {
    // width: 1200px;
    // height: 740px;
    width: fit-content;
    height: fit-content;
    padding: 37px 24px;

    border-radius: 10px;
    border: 2px solid rgba(255, 255, 255, 0.7);
    background: linear-gradient(
        113deg,
        rgba(255, 255, 255, 0.47) 0%,
        rgba(255, 255, 255, 0) 110.84%
    );
    backdrop-filter: blur(30px);

    color: #cdd0d8;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;

    @media screen and (max-width: 1024px) {
        padding: 24px 16px;
    }
    @media screen and (max-width: 767px) {
        padding: 17px 16px;
    }
}
.Dots {
    @media screen and (max-width: 767px) {
        svg {
            height: 8px;
            width: 34px;
        }
    }
}
.image {
    overflow: hidden;
    display: flex;
    border-radius: 10px;
}

.texture {
    opacity: 0.2;
    background: url("./texture/Texture.png") lightgray 50% /
        cover no-repeat;
    mix-blend-mode: soft-light;
}

.topBlock {
    display: flex;
    align-items: center;
    gap: 25px;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 16px;
}
.addressBar {
    display: flex;
    width: 100%;
    padding: 12px 10px 12px 16px;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    background: #fff;

    -ms-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;

    @media screen and (max-width: 1024px) {
        padding: 6px 5px 6px 8px;
    }

    @media screen and (max-width: 767px) {
        height: 14px;
        width: 100%;
        p {
            font-size: 9px;
        }
    }
}
