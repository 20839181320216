@import "src/app/global/variables.scss";
.switcher {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
}

.longArrow {
    cursor: pointer;
    fill: $black-blue;
    &.left {
        transform: rotateZ(180deg);
    }
}

.Pagination {
    color: $black-blue;
    -webkit-touch-callout: none; 
    -webkit-user-select: none; 
    -khtml-user-select: none; 
    -moz-user-select: none; 
    -ms-user-select: none; 
    user-select: none; 
}

.currentPage {
    font-size: $font-m;

    @media screen and (max-width: 767px) {
        font-size: 32px;
    }
}
.totalPages {
    font-size: $font-s;
}
