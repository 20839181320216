@import "src/app/global/variables.scss";
.IdealFor {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 120px;

    h2 {
        color: $black-blue;
    }
    @media screen and (max-width: 767px) {
        width: calc(100vw - 48px);
    }
}

.list {
    display: flex;
    flex-direction: column;
    gap: 51px;
    margin-top: 72px;

    padding-inline-start: 0px;

    li {
        display: flex;
        gap: 132px;

        img {
            border-radius: 20px;
        }

        h2 {
            font-size: 36px;
        }

        p {
            width: 387px;
        }

        @media screen and (max-width: 1024px) and (min-width: 768px) {
            gap: 56px;
            img {
                max-width: 50%;
            }
        }

        @media screen and (max-width: 767px) {
            flex-direction: column;
            gap: 18px;
            img {
                border-radius: 0px;
            }
            &.second {
                flex-direction: column-reverse;
            }
        }
    }

    @media screen and (max-width: 767px) {
        width: 100vw;
        padding: 0;
    }
}

.textContainer {
    display: flex;
    flex-direction: column;
    gap: 24px;
    min-width: 0;
    justify-content: center;

    @media screen and (min-width: 768px) and (max-width: 1024px){
        h2 {
            white-space: nowrap;
        }
        p {
            max-width: 100%;
        }
    }

    @media screen and (max-width: 767px) {
        padding: 0px 24px;
    }
}
