@import "src/app/global/variables.scss";
html,
body {
    overflow-x: hidden;
}

.App {
    background-color: $background-color;
    font-family: "Poppins";

    position: relative;

    display: flex;
    justify-content: center;
}

.App-backgroundImage {
    position: absolute;
    width: 100vw;

    max-height: 1200px;
    left: 0;
    top: 0px;
    z-index: 2;
    pointer-events: none;
    @media screen and (max-width: 767px) {
        padding-top: 200px;
    }

    @media screen and (max-width: 1024px) and (min-width: 768px) {
        height: 711px;
    }
}

.App-footer-backgroundImage {
    position: absolute;
    width: 100vw;

    max-height: 1600px;
    height: 1600px;
    left: 0;
    bottom: -400px;
    z-index: 2;
    pointer-events: none;
    @media screen and (max-width: 767px) {
        width: 1920px;
        height: 1300px;
        left: -1250px;
    }

    @media screen and (max-width: 1024px) and (min-width: 768px) {
        height: 1270px;
        bottom: -350px;
    }

    @media screen and (max-width: 1440px) and (min-width: 1025px) {
        height: 1370px;
        bottom: -350px;
    }
}

.app-inner-container {
    max-width: 1920px;
    width: 100%;
}
