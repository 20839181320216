@import "src/app/global/variables.scss";
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 100vw;
    left: 0;
    @media screen and (max-width: 1024px) {
        h2 {
            font-size: 24px;
        }
        p {
            font-size: 16px;
        }

        h4 {
            font-size: 32px;
        }
    }
}
.AboutPage {
    max-width: 1920px;
    margin-top: 130px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 222px;
    margin-right: 222px;

    @media screen and (max-width: 1024px) {
        width: calc(100vw - 48px);
        margin-left: 24px;
        margin-right: 24px;
        padding: 0px 24px;
    }
}

.title {
    color: white;
    text-transform: capitalize;
}

.bgWave {
    position: absolute;
    width: 100%;
    top: -600px;
    max-height: 1000px;
    z-index: -1;
    left: 0;

    @media screen and (max-width: 1440px) {
        top: -400px;
    }

    @media screen and (max-width: 1024px) and (min-width: 768px) {
        height: auto;
        top: -200px;
        width: 100vw;
    }
    @media screen and (max-width: 1024px) and (min-width: 1024px) {
        top: -400px;
    }
    @media screen and (max-width: 767px) {
        height: auto;
        top: -200px;
        width: 800px;
    }
}
.frame {
    width: 728px !important;
    height: 483px !important;
    box-shadow: 0px 10px 20px 5px
        rgba($color: $black-blue, $alpha: 0.08);
    margin-top: 42px;
    img {
        height: 520px;
        object-fit: cover;
    }

    position: relative;
    z-index: 2;

    @media screen and (max-width: 1280px) {
        img {
            height: 520px;
        }
    }

    @media screen and (max-width: 767px) {
        left: 180px;
    }
}

.ending {
    width: 100%;

    display: flex;
    justify-content: center;
    background-size: cover;

    color: white;
    position: relative;
    top: 100px;
    padding-top: 68px;
    gap: 117px;

    @media screen and (max-width: 1280px) {
        flex-direction: column;
        align-items: center;
        gap: 0px;
    }

    @media screen and (max-width: 767px) {
        flex-direction: column;
        align-items: center;
        text-align: center;
        top: 0px;
        gap: 50px;
        padding-top: 0px;

        h2 {
            font-size: 48px;
        }
    }
}

.getButton {
    width: 100%;

    @media screen and (max-width: 1280px) {
        width: auto;
    }

    @media screen and  (max-width: 767px) {
        width: 100%;
    }
}

.getTrial {
    margin-top: 106px;

    p {
        margin-bottom: 32px;
    }

    position: relative;
    z-index: 2;

    @media screen and (max-width: 1280px) {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 32px;
        margin-top: 0px;
    }

    @media screen and (max-width: 767px) {
        flex-direction: column;
        margin-top: 64px;
    }
}


.BackGraph {
    position: absolute;
    top: 150px;
    left: 120px;
    z-index: 1;
    pointer-events: none;

    @media screen and (min-width: 2500px) {
        left: calc(50% - 800px);
    }
}

.footer {
    position: relative;
}
