@import "src/app/global/variables.scss";
.ContactForm {
    display: flex;
    flex-direction: column;
    gap: 32px;
    background-color: white;
    padding: 20px 42px 53px 42px;
    border-radius: 12px;

    h2 {
        font-size: 36px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    h3 {
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
}

.submitButton {
    width: 100%;
}

.personalInfo {
    position: relative;

    display: flex;
    flex-direction: column;
    gap: 21px;
    width: 100%;
}

.ContactForm.success {
    display: flex;
    padding: 32px;
    align-items: center;
    justify-content: center;

    svg {
        height: 150px;
        width: 150px;
    }
}
