@import "src/app/global/variables.scss";
.TextField {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 398px;

    span {
        color: $secondary-color;
    }

    h2 {
        font-size: 36px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    h3 {
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    h4 {
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    input {
        border-radius: 5px;
        border: 1px solid #cecece;
        outline: $secondary-color;
        padding: 2px 16px;
        height: 42px;
        margin-top: 8px;
    }
    input:focus {
        border: 1px solid $secondary-color;
    }

    &.invalid {
        input {
            border: 1px solid red;
        }
        .description {
            color: red;
        }
    }

    @media screen and (max-width: 767px) {
        // margin-left: 24px;
        margin-right: 24px;
        width: calc(100%);
        input {
            width: auto;
        }
    }
}

.description {
    font-size: 14px;
}
