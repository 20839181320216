@import "src/app/global/variables.scss";
.Footer {
    position: absolute;
    z-index: 10;
    bottom: 0;

    background-color: $black-blue;

    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    left: 0;

    padding: 48px 0px;

    h4 {
        color: #fff;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 160%; /* 32px */
    }

    p {
        color: #adabb7;
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 28.8px */
        white-space: nowrap;
    }

    @media screen and (max-width: 767px) {
        flex-direction: column;
        gap: 42px;
        padding: 0px 0px;
    }

    @media screen and (max-width: 1024px) and (min-width: 768px) {
        p {
            white-space: wrap;
            max-width: 181px;
        }
    }
}

.footerInner {
    max-width: 1920px;
    width: 100%;
    margin-left: 120px;
    margin-right: 120px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 767px) {
        flex-direction: column;
        gap: 16px;
        padding: 30px 120px;
    }
    @media screen and (max-width: 1024px) and (min-width: 768px) {
        gap: 42px;
        margin-left: 64px;
        margin-right: 64px;
    }
}

.authorship {
    display: flex;
    flex-direction: column;
    gap: 32px;

    p {
        cursor: auto;
    }

    @media screen and (max-width: 1024px) and (min-width: 768px) {
        p {
            font-size: 12px;
        }
    }
}

.aboutGraphiX {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.timeline {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.links {
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.footerLogo {
    position: relative;
    left: -80px;
    @media screen and (max-width: 767px) {
        left: 0;
    }
    @media screen and (max-width: 1024px) and (min-width: 768px) {
        position: relative;
        left: 40px;
    }
}
