@import "src/app/global/variables.scss";
.Checkbox {
    display: flex;
    align-items: center;
    gap: 4px;
}

.input {
    position: absolute;
    z-index: -10;
    opacity: 0;
}

.input + .checkBoxLabel {
    display: inline-flex;
    align-items: center;
    user-select: none;
}
.input + .checkBoxLabel::before {
    content: "";
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    flex-shrink: 0;
    flex-grow: 0;
    border: 1px solid #adb5bd;
    border-radius: 0.25em;
    margin-right: 0.5em;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
    cursor: pointer;
}

.input:checked + .checkBoxLabel::before {
    border-color: $primary-color;
    background-color: $primary-color;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='white' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}
