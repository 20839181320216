@import "src/app/global/variables.scss";
.PaymentDetail {
    display: flex;
    flex-direction: column;
    gap: 32px;
    background-color: white;
    padding: 20px 42px 53px 42px;
    border-radius: 12px;

    h2 {
        font-size: 36px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    h3 {
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
}

.personalInfo {
    position: relative;

    display: flex;
    flex-direction: column;
    gap: 21px;
    width: 100%;
}


.iconsContainer {
    display: flex;
    gap: 4px;

    position: absolute;
    left: calc(100% - 64px);
    top: 60%;
}

.methodContainer {
    display: flex;
    gap: 15px;
}

.paymentButton {
    width: 100%;
}

.container {
    display: flex;
    gap: 32px;
}

.input {
    font-size: 14px;
    width: 398px;

    @media screen and (max-width: 767px) {
        width: auto;
    }
}

.privacyLink {
    p {
        font-size: 14px;
    }

    span {
        color: $secondary-color;
    }

    @media screen and (max-width: 767px) {
    }
}


.privacyLegacyLink {
    text-decoration: none;
    cursor: pointer;
}
