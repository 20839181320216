@import "src/app/global/variables.scss";
.ContactForm {
    display: flex;
    flex-direction: column;
    gap: 32px;
    background-color: white;
    padding: 20px 42px 53px 42px;
    border-radius: 12px;

    h2 {
        font-size: 36px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    h3 {
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: capitalize;
    }

    h4 {
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    p {
        font-size: 15px;
        text-align: center;
    }

    input {
        border-radius: 5px;
        border: 1px solid #cecece;
        outline: $secondary-color;
        padding: 2px 16px;
        height: 42px;
        width: 400px;
        margin-top: 8px;
    }

    padding: 20px 24px 53px 24px;

    @media screen and (max-width: 767px) {
        input {
            width: 250px;
        }
    }
}

.submitButton {
    width: 100%;
}

.ContactForm.success {
    display: flex;
    padding: 32px;
    align-items: center;
    justify-content: center;

    svg {
        height: 48px;
        width: 48px;
    }
}