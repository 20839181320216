@import "src/app/global/variables.scss";
* {
    font-family: "Poppins", sans-serif, -apple-system,
        BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue";
    margin: 0;
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: hidden;
    background-color: $background-color;
}
a {
    text-decoration: none;
    color: inherit;
    max-width: fit-content;
}
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas,
        "Courier New", monospace;
}

h1 {
    font-size: $font-xl;
}

h2 {
    font-size: $font-l;
}

h3 {
    font-size: $font-m;
}

h4 {
    font-size: $font-sm;
    font-weight: 400;
}

p {
    font-size: $font-s;
}

@media screen and (max-width: 1024px) {
    h1 {
        font-size: 36px;
    }

    h2 {
        font-size: 36px;
    }

    h3 {
        font-size: $font-m;
    }

    h4 {
        font-size: 16px;
        font-weight: 400;
    }

    p {
        font-size: 16px;
    }
}

@media screen and (max-width: 767px) {
    h1 {
        font-size: 32px;
    }

    h2 {
        font-size: 32px;
    }

    h3 {
        font-size: $font-m;
    }

    h4 {
        font-size: 16px;
        font-weight: 400;
    }

    p {
        font-size: 16px;
    }
}
