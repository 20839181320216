@import "src/app/global/variables.scss";
.container {
    position: relative;

    overflow-y: hidden;
    @media screen and (max-width: 767px) {
        padding-bottom: 200px;
    }
}

.FarewellBlock {
    position: relative;
    display: flex;
    overflow-y: hidden;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 3;
    margin-top: 400px;
    @media screen and (max-width: 1024px) {
        margin-top: 300px;
        margin-bottom: 100px;
    }

    @media screen and (max-width: 767px) {
        margin-top: 0;
        padding-bottom: 200px;
        top: 200px;
        margin-top: -150px;
    }
}
.Background {
    position: absolute;
    width: 100%;
    left: 0;
    top: 237px;
    z-index: 2;

    @media screen and (max-width: 1024px) {
        top: 100px;
    }
}

.headline {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 96px;
    color: white;

    position: relative;
    top: 0px;
    z-index: 3;
    h2 {
        font-size: 36px;
        text-align: center;
    }
    p {
        font-size: 14px;
        text-align: center;
    }

    @media screen and (max-width: 767px) {
        flex-direction: column;
        padding-top: 50px;
        gap: 32px;
        h2 {
            font-size: 24px;
            text-align: center;
        }
        p {
            font-size: 14px;
            text-align: center;
        }
        .getButton {
            max-width: 320px;
        }
    }

    @media screen and (max-width: 1024px) and (min-width: 768px) {
        h2 {
            font-size: 24px;
        }
    }
}

.getButton {
    max-height: 72px;
    pointer-events: all;
}

.wave {
    position: relative;

    fill: red;
    z-index: 2;

    pointer-events: none;
}

.Illustration {
    position: absolute;
    left: 112px;
    top: 80px;
    z-index: 1;

    @media screen and (max-width: 1024px) and (min-width: 768px) {
        top: 0px;
        left: 64px;
    }
}

.frame {
    position: relative;
    pointer-events: none;
    top: 80px;

    img {
        object-fit: cover;
        width: 1143px;
    }

    @media screen and (max-width: 1440px) and (min-width: 1025px) {
        img {
            width: 900px;
        }
    }

    @media screen and (max-width: 1024px) {
        top: 100px;
        img {
            width: 714px;
        }
    }

    @media screen and (max-width: 767px) {
        left: 87px;

        img {
            width: 453px;
        }
    }
}

.bgImage {
    position: absolute;
    width: 600px;
    top: 200px;
}
