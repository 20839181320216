@import "src/app/global/variables.scss";
.Logo {
    display: flex;
    align-items: center;
    gap: 9px;
    font-size: 24px;
    color: $text-color;
    font-weight: 700;

    @media screen and (min-width: 1024px) {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    @media screen and (max-width: 1024px) and (min-width: 768px) {
        justify-content: center;
        left: -21px;
        position: relative;
        transform: translateX(-40px);
    }

    @media screen and (max-width: 767px){
        transform: scale(0.8);
        left: -42px;
        position: relative;
    }
}
