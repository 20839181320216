@import "src/app/global/variables.scss";
.container {
    position: relative;
}

.BusinessInfo {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: max-content;

    font-family: 'Poppins';

    padding: 16px 32px;
    border-radius: 5px;
    color: white;
    font-weight: 600;
    font-size: 16px;
    gap: 10px;

    background-color: transparent;
    border: 1px solid white;

    cursor: pointer;
    transition-duration: 500ms;
    &:hover {
        background-color: white;
        color: black;
        border: 1px solid ;
        .arrow {
            path {
                stroke: black;
            }
        }
    }
    &.open {
        background-color: white;
        color: black;
        border: 1px solid #FFF;

        .arrow {
            path {
                stroke: black;
            }
        }
    }
}


.arrow {
    transition-duration: 500ms;
}
.arrow.open {
    transform: rotateZ(180deg);
}

.menu {
    position: absolute;
    top: -336px;
    left: calc(-100% - 200px);

    border: 1px solid rgba(180, 180, 180, 0.3);
    background-color: white;

    border-radius: 12px;
    padding: 16px;

    max-height: 300px;
    overflow-y: auto;
    height: 300px;
    opacity: 1;
    
    transition-duration: 500ms;


    h3 {
        font-size: 20px;
    }

    &.close {
        top: 0;
        opacity: 0;
        height: 0;
        pointer-events: none;
    }

    &::-webkit-scrollbar {
        width: 5px; /* ширина scrollbar */
        background-color: transparent;
    }
    &::-webkit-scrollbar-track {
        border-radius: 20px; /* цвет дорожки */
        opacity: 50%;
    }
    &::-webkit-scrollbar-thumb {
        background-color: rgba(180, 180, 180, 0.3); /* цвет плашки */
        border-radius: 20px; /* закругления плашки */
    }
    &::-webkit-scrollbar-track-piece:end {
        margin-bottom: 12px;
    }

    &::-webkit-scrollbar-track-piece:start {
        background: transparent;
        margin-top: 20px;
    }

    @media screen and (max-width: 767px) {
        left: -71px;
    }
}

.tableBody {
    display: flex;
    flex-direction: column;
    gap: 20px;
    font-family: 'Poppins';
    tr {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid rgba(210, 210, 210, 0.7);
        padding: 4px;

        &.large {
            display: flex;
            flex-direction: column;
            gap: 4px
        }
    }

    th {
        min-width: 120px;
        text-align: left;

        font-size: 16px;
        font-weight: 600;
    }
    td {
        font-weight: 400;
    }

    
}