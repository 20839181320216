@import "src/app/global/variables.scss";
.content {
    margin-top: 217px;

    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 996px;

    h2 {
        color: $black-blue;
    }

    @media screen and (max-width: 1024px) {
        width: calc(100vw - 48px);
    }
}

.textContainer {
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin-top: 16px;

    p {
        font-size: 18px;
    }
}

.frame {
    box-shadow: 0px 10px 20px 5px
        rgba($color: $black-blue, $alpha: 0.08);
    margin-top: 42px;
    img {
        object-fit: cover;
        width: 950px;
    }

    @media screen and (max-width: 1024px) and (min-width: 768px) {
        img {
            width: 715px;
        }
    }

    @media screen and (max-width: 767px) {
        img {
            width:312px;
        }
    }
}

.advantages {
    position: relative;
    display: flex;
    align-items: center;
    margin-top: 32px;
    max-width: 996px;
}

.advantagesText {
    min-width: 536px;
    max-width: 530px;
    @media screen and (max-width: 1024px) {
        min-width: auto;
    }
}

.list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;

    li {
        display: flex;
        gap: 24px;
    }
}

.vMark {
    min-width: 24px;
    min-height: 24px;
}

.graphic {
    position: relative;

    top: 0px;
    right: -100px;
    min-width: 770px;
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;

    @media screen and (max-width: 1024px) {
        display: none;
    }
}

.graph {
    object-fit: cover;
    max-height: 520px;
    border-radius: 10px;
}

.getStartedButton {
    place-self: flex-start;
    margin-top: 32px;
}
