

$black-blue: #20184E;
$gray-color: #666;

$primary-color: #0CF18A;
$secondary-color: #0944CC;
$text-color: #FFFFFF;
$error-color: #FF0000;
$background-color: #f9f9f9;
$brand-color: #8828FF;

