@import "src/app/global/variables.scss";
.PolicyLegacy {
    position: relative;
    background-color: $background-color;
    display: flex;
    flex-direction: column;
    align-items: center;

    table {
        width: 100%;
        border-collapse: collapse;
        margin-top: 20px;
    }
    table,
    table th,
    table td {
        border: 1px solid #ddd;
    }
    table th,
    table td {
        padding: 8px;
        text-align: left;
    }
    table th {
        background-color: #f2f2f2;
    }

    @media screen and (max-width: 767px) {
        table th, table td {
            padding: 2px;
        }
        table {
            font-size: 9px;
        }
    }
}

.container {
    position: relative;
    max-width: 1920px;

    @media screen and (max-width: 767px) {
        max-width: 100vw;
    }
}

.title {
    position: relative;
    margin-top: 130px;
    text-align: center;
    color: white;
    z-index: 2;
}

.bgWave {
    position: absolute;
    width: 100%;
    top: -600px;
    max-height: 1000px;
    z-index: 1;
    left: 0;
    @media screen and (max-width: 1024px) {
        top: -400px;
    }
    @media screen and (max-width: 1024px) and (min-width: 768px) {
        height: 700px;
        top: -400px;
    }
    @media screen and (max-width: 767px) {
        height: auto;
        top: -200px;
        width: 800px;
    }

    @media screen and (max-width: 1440px) and (min-width: 1025px) {
        top: -300px;
    }
}

.textContainer {
    display: flex;
    flex-direction: column;
    gap: 56px;
    margin-top: 350px;
    margin-left: 335px;
    margin-right: 335px;

    h2 {
        color: #333;
    }
    ul {
        list-style-type: none;
        padding-left: 0;
    }
    ul li::before {
        content: "•";
        padding-right: 8px;
    }

    @media screen and (max-width: 767px) {
        margin-left: 24px;
        margin-right: 24px;
        margin-top: 130px;
    }

    @media screen and (max-width: 1024px) and (min-width: 767px) {
        margin-left: 120px;
        margin-right: 120px;
        margin-top: 150px;
    }
    @media screen and (max-width: 1440px) and (min-width: 1025px) {
        margin-left: 120px;
        margin-right: 120px;
        margin-top: 450px;
    }
}

.item {
    font-size: 36px;
    color: black;
    font-weight: 700;
}

.subitem {
    display: flex;
    flex-direction: column;
    gap: 32px;
    font-size: 16px;
    font-weight: 300;
    color: black;
}

.date {
    color: black;
    font-size: 14px;
}

.point {
    margin-left: 12px;
    list-style: disc;
}

.footer {
    position: relative;
    margin-top: 120px;
}
