@import "src/app/global/variables.scss";
.actionsContainer {
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 767px) {
        position: absolute;
        bottom: -420px;
        align-items: center;
        min-width: 100%;
    }

    @media screen and (max-width: 1024px) {
        flex-direction: column;
        max-width: fit-content;
        gap: 32px;
        a {
            width: 100%;
            max-width: none;
            .readMoreButton {
                width: 100%;
            }
        }
    }
}

.title {
    text-transform: uppercase;
    font-weight: 500;
}

.textContainer {
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 32px;
    min-width: 530px;
    max-width: 530px;
    h2 {
        text-transform: capitalize;
        color: $black-blue;
    }

    p {
        color: $gray-color;
        white-space: pre-line;
    }

    @media screen and (max-width: 767px) {
        padding-left: 0px;
        min-width: 300px;
        max-width: 300px;
    }

    @media screen and (max-width: 1024px) and (min-width: 768px) {
        min-width: 374px;
        max-width: 374px;
    }
}

.ArrowRight {
    transition-duration: 300ms;
}

.frameContainer {
    max-width: 800px;
    overflow: hidden;
    display: flex;
    flex-wrap: nowrap;
    left: 50px;
    position: relative;
    z-index: 3;
    pointer-events: none;

    @media screen and (max-width: 767px) {
        padding: 40px 24px 100px 24px;
        align-items: center;
    }

    @media screen and (min-width: 1920px) {
        max-width: none;
        width: 1300px;
        left: 300px;
    }
}

.frame {
    position: relative;
    z-index: 4;
    left: 100px;
    min-width: 820px;
    img {
        object-fit: cover;

        height: 504px;
    }

    @media screen and (max-width: 1024px) and (min-width: 768px) {
        left: 0px;
        min-width: 425px;
        img {
            height: 302px;
        }
    }

    @media screen and (max-width: 767px) {
        left: 0px;

        box-shadow: 0px 0px 30px 5px rgba(0, 0, 0, 0.05);
        min-width: 340px;
        img {
            height: 240px;
        }
    }

    @media screen and (min-width: 1920px) {
        min-width: 0px;
    }
}

.frameImage {
    background-color: white;
    border-radius: 10px;
}

.circle {
    position: absolute;
    left: 40%;
    top: -100px;
    pointer-events: none;
    width: 633px;
    height: 615px;
    flex-shrink: 0;
    border-radius: 633px;
    opacity: 0.1;
    background: $primary-color;
    filter: blur(60px);
}

.circleViolet {
    position: absolute;
    left: 65%;
    top: 300px;
    pointer-events: none;
    width: 633px;
    height: 615px;
    flex-shrink: 0;
    border-radius: 633px;
    opacity: 0.1;
    background: $secondary-color;
    filter: blur(60px);
}

.dots {
    circle {
        fill: gray;
        opacity: 0.1;
    }
    margin-top: 80px;
    transform: rotateY(180deg);

    @media screen and (max-width: 767px) {
        display: none;
    }
}

.sliderContainer {
    min-height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 32px;
}

.readMoreButton {
    max-width: fit-content;
    @media screen and (max-width: 767px) {
        width: 100%;
        max-width: none;
    }
}
