@import "src/app/global/variables.scss";
.BlogBlock {
    margin-top: 200px;
    padding-left: 120px;

    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 767px) {
        margin-top: 64px;
        margin-bottom: 0px;
        flex-direction: column;
        padding-left: 0;
    }
    @media screen and (max-width: 1024px) and (min-width: 768px) {
        padding-left: 24px;
        margin-top: 100px;
    }
}

.textContainer {
    max-width: 590px;
    @media screen and (max-width: 767px) {
        padding-left: 24px;
    }

    @media screen and (max-width: 1024px) and (min-width: 768px) {
        h2  {
            font-size: 32px;
        }
    }
}

.title {
    color: $black-blue;
    text-transform: capitalize;
    padding-left: 24px;
    padding-right: 24px;

    @media screen and (max-width: 767px) {
        font-size: 28px !important;
    }
}

.text {
    color: $gray-color;
    max-width: 590px;
    padding-left: 24px;
    padding-right: 24px;
}

.postsContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    overflow: hidden;
}

.postsList {
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    gap: 20px;
    padding: 20px;
}

.controller {
    display: flex;
    gap: 18px;
    place-self: end;
    margin-right: 120px;
}

.arrow {
    fill: $secondary-color;
    cursor: pointer;

    &.disabled {
        pointer-events: none;
        opacity: 0.5;
    }
}

.left {
    transform: rotateZ(180deg);
}
