@import "src/app/global/variables.scss";
.Button {
    padding: 16px 54px;
    font-size: $font-xs;
    font-weight: 500;

    border-radius: 5px;
    border: 1px inset transparent;
    transition-duration: 300ms;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        transform: scale(1.05);
    }

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;

    &.disabled {
        opacity: 0.4;
        background: $primary-color;
        color: $text-color;
        cursor: not-allowed;
        pointer-events: none;
    }
}

.primary {
    background: $primary-color;
    color: $text-color;
    border: 1px solid $primary-color;
}

.secondary {
    background: $secondary-color;
    color: $text-color;
}

.outlined {
    background: $text-color;
    color: $secondary-color;
    border: 2.5px solid $secondary-color;
    font-size: $font-s;
}

.white {
    background: $text-color;
    color: $secondary-color;
}

.extraSmall {
    padding: 12px 42px;
}

.small {
    padding: 16px 42px;
}

.medium {
    padding: 16px 56px;
}

.large {
    padding: 24px 72px;
    font-size: $font-xs;
}
