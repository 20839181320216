@import "src/app/global/variables.scss";
.Header {
    position: fixed;
    padding: 32px 72px;
    max-width: 1920px;
    width: calc(100vw - 144px);

    display: flex;
    align-items: center;
    justify-content: space-between;

    transition-duration: 500ms;
    top: 0;
    left: 0;
    &.down {
        top: -200px;
    }
    z-index: 9999;

    &.back {
        background-color: white;
        padding-top: 12px;
        padding-bottom: 12px;

        .loginButton {
            background-color: #eef9ff;
        }
    }

    @media screen and (min-width: 1932px) {
        padding-left: calc((100vw - 1920px) / 2);
        padding-right: calc((100vw - 1920px) / 2);
    }
}

.tabletHeader {
    position: fixed;
    z-index: 9999;
    width: 100%;
    top: 0;
    transition-duration: 500ms;
    &.down {
        top: -200px;
    }

    @media screen and (max-width: 1024px) {
        a {
            width: 100%;
            max-width: none;
            .loginButton,
            .getButton {
                width: 100%;
            }
        }

        .mobileLogo {
            position: absolute;
            top: 32px;
            left: calc(50% - 49px / 2);
            width: 112px;
        }
    }

    @media screen and (max-width: 768px) {
        .mobileLogo {
            position: absolute;
            top: 16px;
            left: calc(50% - 49px / 2);
        }
    }
}

.buttonContainer {
    display: flex;

    gap: 20px;

    @media screen and (max-width: 1024px) {
        flex-direction: column;
        padding: 42px;
    }

    @media screen and (min-width: 1025px) and (max-width: 1440px) {
        .getButton,
        .loginButton {
            padding: 10px 18px;
        }
    }
}

.Logo {
    color: white;
}

.LogoContainer {
    width: 100%;
}

.closedMenu {
    display: flex;
    align-items: center;
    justify-content: start;
    width: 100%;
    padding: 32px 72px;

    &.back {
        background-color: white;
    }

    &.open {
        background-color: $secondary-color;
    }

    @media screen and (max-width: 1024px) {
        padding: 32px;

        .icon {
            width: 32px;
            height: 32px;
            padding-top: 16px;
            padding-bottom: 6px;
        }
    }

    @media screen and (max-width: 767px) {
        gap: 120px;
        padding: 16px 32px;
        .icon {
            width: 24px;
            height: 24px;
            padding-top: 16px;
            padding-bottom: 6px;
        }
    }
}

.icon {
    position: relative;
    z-index: 10;
    path {
        stroke: white;
    }

    &.back {
        path {
            stroke: black !important;
        }
    }
}

.tabletMenu {
    position: fixed;
    padding-top: 100px;
    z-index: 999;
    background: radial-gradient(
                farthest-side,
                rgba($color: $primary-color, $alpha: 0.5) 5%,
                transparent
            ) -350px /600px 600px,
        radial-gradient(
                farthest-side,
                rgba($color: $primary-color, $alpha: 0.5) 5%,
                transparent
            ) -100px 400px / 800px 800px,
        radial-gradient(
                farthest-side,
                rgba($color: $brand-color, $alpha: 0.5) 5%,
                transparent
            )
            150px 300px/500px 500px,
        radial-gradient(
                farthest-side,
                rgba($color: $brand-color, $alpha: 0.5) 5%,
                transparent
            )
            200px -50px /400px 400px;
    /* the circle                                          position / size */
    background-repeat: no-repeat;
    background-color: $secondary-color;
    width: 100vw;
    top: 0px;
    transition-duration: 300ms;
    &.close {
        top: calc(-100vh - 1200px);
    }

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;

    @media screen and (min-width: 768px) and (max-width: 1024px) {
        background: radial-gradient(
                    farthest-side,
                    rgba(
                            $color: $primary-color,
                            $alpha: 0.5
                        )
                        5%,
                    transparent
                ) -450px /600px 600px,
            radial-gradient(
                    farthest-side,
                    rgba(
                            $color: $primary-color,
                            $alpha: 0.5
                        )
                        5%,
                    transparent
                )
                0px 900px / 800px 800px,
            radial-gradient(
                    farthest-side,
                    rgba($color: $brand-color, $alpha: 0.5)
                        5%,
                    transparent
                )
                800px 300px/500px 500px,
            radial-gradient(
                    farthest-side,
                    rgba($color: $brand-color, $alpha: 0.5)
                        5%,
                    transparent
                )
                800px -50px /400px 400px;
        background-repeat: no-repeat;
        background-color: $secondary-color;
    }
}

.openedMenu {
    position: relative;
    z-index: 9999;
    width: calc(100vw - 32px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    .langSelect {
        padding: 0;
        padding-right: 32px;
        p,
        svg {
            mix-blend-mode: normal;
        }
    }
}

.mobileNavigation {
    mix-blend-mode: normal;
    display: flex;
    flex-direction: column;
    margin-left: 42px;

    align-items: start;
    @media screen and (min-width: 768px) and (max-width: 1024px) {
        margin-top: 42px;
        p {
            font-size: 24px;
        }
    }
}

.langSelect {
    background-color: transparent !important;
    color: white !important;
    text-transform: uppercase;
    top: -5px;
    path {
        stroke: white !important;
    }

    &.back {
        color: black !important;
        path {
            stroke: black !important;
        }
    }
}
