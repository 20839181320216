@import '../themes/light.scss';

//typography sizes
$font-xxs: 14px; 
$font-xs: 16px;
$font-s: 18px;
$font-sm: 20px;
$font-m: 24px;
$font-l: 48px;
$font-xl: 64px; 

//borders
$border-xs: 5px;
$border-s: 8.276px;


//mobile typography sizes
$mobile-font-xxs: 14px; 
$mobile-font-xs: 16px;
$mobile-font-s: 16px;
$mobile-font-sm: 16px;
$mobile-font-m: 24px;
$mobile-font-l: 32px;
$mobile-font-xl: 64px; 